import React from "react";
import ReactDOM from "react-dom/client";
import { App as AntApp } from "antd";
import "./app.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import DeepDiveProvider from "./DeepDiveProvider";
import ThemeProvider from "./ThemeProvider";
import AgoraRTC from "agora-rtc-react";

import "simplebar-react/dist/simplebar.min.css";

// Fonts
import "@fontsource/inter/100.css";
import "@fontsource/inter/200.css";
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import "@fontsource/inter/900.css";
import RTMProvider from "./RTMProvider";

// Internationalization
import "./i18n";

AgoraRTC.setLogLevel(4); // set log level to none for agora

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider>
        <AntApp>
          <DeepDiveProvider>
            <RTMProvider>
              <App />
            </RTMProvider>
          </DeepDiveProvider>
        </AntApp>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);
