import { Typography } from "antd";
import CustomIcon from "../CustomIcon";
import { useDeepDiveContext } from "../../DeepDiveProvider";

const { Text } = Typography;

const NoScreenShared = () => {
  const { t } = useDeepDiveContext();

  return (
    <div className='d-flex flex-column align-items-center flex-center gap-2 w-220px text-center m-auto h-100'>
      <CustomIcon name='noParticipants' />
      <Text>{t("NO_SCREEN_SHARED")}</Text>
    </div>
  );
};

export default NoScreenShared;
