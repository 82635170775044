import CustomIcon from "./CustomIcon";
import { Tag, Tooltip, Typography } from "antd";
import { IAgoraRTCRemoteUser } from "agora-rtc-react";
import { DEEPDIVE_ROLE_ICONS, getBackground, STREAM_TYPE } from "../constants";
import { useDeepDiveContext } from "../DeepDiveProvider";
import { Joinee, Uids } from "../deepdiveSlice";

const { Text } = Typography;

const UserTrackInfo = ({
  isLocal,
  remoteUser = undefined,
  shouldEnableAudio = true,
}: {
  isLocal: boolean;
  remoteUser?: IAgoraRTCRemoteUser;
  shouldEnableAudio?: boolean;
}) => {
  const { joinee, micOn, cameraOn, members } = useDeepDiveContext();
  const {
    uids = {},
    joinee: { name, role },
  } = joinee as Joinee;
  const { cameraAudioUid, screenUid } = uids as Uids;

  const isSelf = remoteUser?.uid === cameraAudioUid || remoteUser?.uid === screenUid;

  const remoteStream = members && remoteUser?.uid ? members[Number(remoteUser.uid)] : null;

  const userName = isLocal ? name : remoteStream?.name || remoteUser?.uid?.toString();

  const icon = isLocal
    ? DEEPDIVE_ROLE_ICONS[role?.toLowerCase() as keyof typeof DEEPDIVE_ROLE_ICONS]
    : DEEPDIVE_ROLE_ICONS[remoteStream?.role?.toLowerCase() as keyof typeof DEEPDIVE_ROLE_ICONS];

  const hasAudio = isLocal ? micOn : remoteUser?.hasAudio && shouldEnableAudio;
  const hasVideo = isLocal ? cameraOn : remoteUser?.hasVideo;
  const isScreen = remoteStream?.stream_type === STREAM_TYPE.SCREEN;

  return (
    <>
      <div
        className={`d-flex align-items-center position-absolute top-10px right-10px gap-1 ${
          hasVideo ? "border-radius-8px p-3px bg-00000080" : ""
        }`}
      >
        {isScreen && <CustomIcon name='share-05' />}
        {!isScreen && !hasAudio && <CustomIcon name='mic-off' fill={hasVideo ? "#ffffff" : "#697586"} />}
        {!isScreen && hasAudio && <CustomIcon name='audio-spectrum-2-1' />}
      </div>

      {!hasVideo && (
        <div className='d-flex align-items-center flex-center position-absolute w-100 h-100'>
          <Tag
            className='border-radius-circle w-40px h-40px d-flex align-items-center flex-center'
            style={{ backgroundColor: getBackground(userName || "") }}
          >
            <Text className='font-size-17px font-weight-500 color-white text-capitalize'>{userName?.toString()?.charAt(0)}</Text>
          </Tag>
        </div>
      )}

      <div className={`user-name stream-${hasVideo ? "on" : "off"}`} style={{ color: hasVideo ? "#ffffff" : "#697586" }}>
        <CustomIcon name={icon} width={18} height={18} />
        <Tooltip title={isLocal || isSelf ? "You" : userName} placement='left'>
          <Text className='text-ellipsis'>{isLocal || isSelf ? "You" : userName}</Text>
        </Tooltip>
      </div>
    </>
  );
};

export default UserTrackInfo;
