import { useParams } from "react-router-dom";
import { useDeepDiveContext } from "../../DeepDiveProvider";
import CustomIcon from "../CustomIcon";
import { Button, Typography } from "antd";

const { Title, Text } = Typography;

const ErrorScreen = () => {
  const { error, verifySession, t, isRecorder } = useDeepDiveContext();

  const { joiningKey } = useParams();

  return (
    <>
      {!isRecorder && (
        <>
          <CustomIcon name='deepdive' />
          <div className='d-flex flex-column flex-center align-items-center mt-4'>
            <CustomIcon name='error' />
            <Title className='font-size-30px'>{t("OOPS")}</Title>
            <Text className='font-size-17px color-426BCD'>{error}</Text>
            <Button className='mt-4' size='large' type='primary' onClick={() => verifySession(joiningKey || "")}>
              {t("REFRESH")}
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default ErrorScreen;
