import AgoraRTC, { AgoraRTCProvider } from "agora-rtc-react";
import ScreenSharing from "../ScreenSharing";
import Call from "../Call";

const CallScreen = () => {
  const videoClient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
  const screenClient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

  return (
    <>
      <AgoraRTCProvider client={videoClient}>
        <Call />
      </AgoraRTCProvider>
      <AgoraRTCProvider client={screenClient}>
        <ScreenSharing />
      </AgoraRTCProvider>
    </>
  );
};

export default CallScreen;
