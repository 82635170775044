import { configureStore } from "@reduxjs/toolkit";
import deepdiveReducer from "./deepdiveSlice";

const store = configureStore({
  reducer: {
    deepdive: deepdiveReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
