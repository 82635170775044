import { Button, Typography } from "antd";
import { useDeepDiveContext } from "../../DeepDiveProvider";
import CustomIcon from "../CustomIcon";
import { CALL_PROGRESS } from "../../constants";

const { Title, Text } = Typography;

const ThankYouScreen = () => {
  const { updateDeepDiveState, endReason, showRejoinBtn, t, isRecorder } = useDeepDiveContext();

  return (
    <>
      {!isRecorder && (
        <>
          <CustomIcon name='deepdive' />
          <div className='d-flex flex-column flex-center align-items-center mt-4'>
            <CustomIcon name='callend' />
            <Title className='font-size-30px'>{t("THANK_YOU_CALL_ENDED")}</Title>
            <Text className='font-size-17px color-426BCD'>{endReason}</Text>
            {showRejoinBtn && (
              <Button
                className='mt-4'
                size='large'
                type='primary'
                onClick={() => updateDeepDiveState({ callProgress: CALL_PROGRESS.WAITING_SCREEN })}
              >
                {t("REJOIN_SESSION")}
              </Button>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ThankYouScreen;
