import { BrowserRouter } from "react-router-dom";
import Router from "./routes";

export default function App() {
  return (
    <BrowserRouter basename={process.env.REACT_APP_BASE_NAME}>
      <Router />
    </BrowserRouter>
  );
}
