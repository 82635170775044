import PropTypes from "prop-types";
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useRef } from "react";
import AgoraRTM from "agora-rtm";
import {
  CALL_END_REASON,
  CALL_PROGRESS,
  capitalizeFirstLetter,
  CHAT_MESSAGE_TYPE,
  CHAT_ROOM,
  INTERACTIONS_BETWEEN_PARTICIPANTS_TYPE,
  SESSION_MODE,
  SIGNAL_TYPE,
  SignalMessage,
  TOAST_TIME,
  USER_TYPE,
  WaitingParticipantInterface,
} from "./constants";
import { AudioChannelsInterface, ChatMessage, Joinee, Member, RecordingInfo, SessionInfo, Uids } from "./deepdiveSlice";
import { App, Typography } from "antd";
import { useDeepDiveContext } from "./DeepDiveProvider";

const { RTM } = AgoraRTM;

const { Text } = Typography;

interface RTMContextType {
  sendSignal: (
    event: SIGNAL_TYPE,
    uid: number,
    joineeId: number,
    member: Member | undefined,
    actOn?: WaitingParticipantInterface | undefined,
    chat?: ChatMessage | undefined,
    msg?: string,
    isReloadRequired?: boolean,
    recordingInfo?: RecordingInfo | undefined,
    audioChannels?: AudioChannelsInterface | undefined,
    interactionBetweenParticipants?: INTERACTIONS_BETWEEN_PARTICIPANTS_TYPE | undefined
  ) => void;
}

// RTMContext to keep the latest state
const RTMContext = createContext<RTMContextType | undefined>(undefined);

// useRTMContext to share this components state everywhere this component is imported
export const useRTMContext = (): RTMContextType => {
  const context = useContext(RTMContext);

  if (!context) throw new Error("useRTMContext context must be used inside RTMProvider");

  return context;
};

interface RTMProviderProps {
  children: ReactNode;
}

const RTMProvider: React.FC<RTMProviderProps> = ({ children }) => {
  const {
    appId,
    screenOn,
    chats,
    joinee,
    tokens,
    sessionInfo,
    channel,
    updateDeepDiveState,
    quitCall,
    joinCall,
    setWaitingParticipants,
    getMembers,
    setNewChatReceived,
    callProgress,
    t,
    limitInteractionDesc,
  } = useDeepDiveContext();

  const { uids = {}, joinee: joineeInfo } = joinee ?? {};
  const { cameraAudioUid } = uids as Uids;
  const { rtmToken = "" } = tokens || {};

  const { message } = App.useApp();

  const rtmRef = useRef<any>(null);

  // Create a ref to hold variables used in the listener
  const listenerVariablesRef = useRef({
    cameraAudioUid,
    updateDeepDiveState,
    screenOn,
    message,
    joineeInfoRole: joineeInfo?.role,
    sessionInfoSessionMode: sessionInfo?.sessionMode,
    quitCall,
    joinCall,
    setWaitingParticipants,
    getMembers,
    setNewChatReceived,
    chats,
    callProgress,
    joinee,
    t,
    sessionInfo,
    limitInteractionDesc,
  });

  // Update the ref whenever the variables change
  useEffect(() => {
    listenerVariablesRef.current = {
      cameraAudioUid,
      updateDeepDiveState,
      screenOn,
      message,
      joineeInfoRole: joineeInfo?.role,
      sessionInfoSessionMode: sessionInfo?.sessionMode,
      quitCall,
      joinCall,
      setWaitingParticipants,
      getMembers,
      setNewChatReceived,
      chats,
      callProgress,
      joinee,
      t,
      sessionInfo,
      limitInteractionDesc,
    };
  }, [
    cameraAudioUid,
    updateDeepDiveState,
    screenOn,
    message,
    joineeInfo?.role,
    sessionInfo?.sessionMode,
    quitCall,
    joinCall,
    setWaitingParticipants,
    getMembers,
    setNewChatReceived,
    chats,
    callProgress,
    joinee,
    t,
    sessionInfo,
    limitInteractionDesc,
  ]);

  // Move the listener function outside useEffect
  const listener = useCallback((e: any) => {
    const {
      cameraAudioUid,
      updateDeepDiveState,
      screenOn,
      message,
      joineeInfoRole,
      sessionInfoSessionMode,
      quitCall,
      joinCall,
      setWaitingParticipants,
      getMembers,
      setNewChatReceived,
      chats,
      callProgress,
      joinee,
      t,
      sessionInfo,
      limitInteractionDesc,
    } = listenerVariablesRef.current;

    const publisher = Number(e.publisher);

    // Ignore messages from self
    if (publisher !== cameraAudioUid) {
      console.log("Message received: ", e);
      const signalMessage: SignalMessage = JSON.parse(e.message)?.message;
      const {
        event,
        from: { member, uid },
        actOn,
        msg,
        chat,
        isReloadRequired,
        recordingInfo,
        audioChannels,
        interactionBetweenParticipants,
      } = signalMessage;
      const { role = "", name = "" } = member || {};

      const selfRole = joineeInfoRole;
      const sessionMode = sessionInfoSessionMode;

      switch (event) {
        // Screen share started
        case SIGNAL_TYPE.SCREEN_SHARE_STARTED: {
          const msg = t("TURNING_OFF_SCREEN_SHARE_DUE_TO_OTHER_USER", { name: name, role: capitalizeFirstLetter(role) });

          let turnOffScreen = false;

          if (role === USER_TYPE.MODERATOR && screenOn) {
            // Moderator is sharing screen, so turn off all screen share
            turnOffScreen = true;
          } else if (role === USER_TYPE.TESTER && selfRole === USER_TYPE.MODERATOR && screenOn) {
            // Tester is sharing screen, so turn off moderator screen share
            turnOffScreen = true;
          } else if (
            role === USER_TYPE.TESTER &&
            selfRole === USER_TYPE.TESTER &&
            sessionMode === SESSION_MODE.FOCUS_GROUP &&
            screenOn
          ) {
            // Tester is sharing screen, so turn off other testers screen share (in focus group)
            turnOffScreen = true;
          }

          if (turnOffScreen) {
            console.log("Turning off screen share: ", msg);
            if (callProgress === CALL_PROGRESS.IN_CALL_SCREEN) {
              message.info(msg, TOAST_TIME);
            }
            updateDeepDiveState({ screenOn: false });
          }

          break;
        }

        // Participant requested to join
        case SIGNAL_TYPE.PARTICIPANT_REQUSTED_TO_JOIN: {
          if (selfRole === USER_TYPE.MODERATOR) {
            // Moderator received a request to allow participant
            setWaitingParticipants((prev: WaitingParticipantInterface[]) => {
              // Check if uid already exists in the waitingParticipants array
              if (!prev.some((participant) => participant.uid === uid)) {
                return [...prev, { uid, member }];
              }
              return prev; // Return the same array if uid is already present
            });
          }
          break;
        }

        // Participant allowed
        case SIGNAL_TYPE.PARTICIPANT_ALLOWED: {
          if (actOn?.uid === cameraAudioUid) {
            // Participant allowed to join
            joinCall(actOn?.member?.name || "");
          }
          if (actOn?.uid !== cameraAudioUid && callProgress === CALL_PROGRESS.IN_CALL_SCREEN) {
            message.success(
              t("USER_JOINED", { name: actOn?.member?.name, role: capitalizeFirstLetter(actOn?.member?.role || "") }),
              TOAST_TIME
            );
          }
          if (selfRole === USER_TYPE.MODERATOR) {
            setWaitingParticipants((prev: WaitingParticipantInterface[]) => {
              return prev.filter((participant) => participant.uid !== actOn?.uid);
            });
          }
          break;
        }

        // Participant denied
        case SIGNAL_TYPE.PARTICIPANT_DENIED: {
          if (actOn?.uid === cameraAudioUid) {
            // Participant denied to join
            updateDeepDiveState({
              callProgress: CALL_PROGRESS.ERROR_SCREEN,
              error: msg,
            });
          }
          break;
        }

        // Chat message received
        case SIGNAL_TYPE.CHAT: {
          if (chat) {
            const room = chat.messageType === CHAT_MESSAGE_TYPE.TEAM ? CHAT_ROOM.INTERNAL : CHAT_ROOM.PARTICIPANT;
            updateDeepDiveState({
              chats: {
                ...chats,
                [room]: [...(chats?.[room] ?? []), chat],
              },
            });
            if (
              (room === CHAT_ROOM.PARTICIPANT && (selfRole === USER_TYPE.MODERATOR || selfRole === USER_TYPE.PARTICIPANT)) ||
              (room === CHAT_ROOM.INTERNAL && selfRole !== USER_TYPE.PARTICIPANT)
            ) {
              setNewChatReceived(true);
            }
          }
          break;
        }

        // Role updated
        case SIGNAL_TYPE.ROLE_UPDATED: {
          if (actOn?.uid === cameraAudioUid) {
            const selfJoinee = joinee as Joinee;
            if (!isReloadRequired) {
              updateDeepDiveState({
                joinee: {
                  ...selfJoinee,
                  joinee: {
                    ...selfJoinee?.joinee,
                    role: actOn?.member?.role || "",
                  },
                },
                audioChannels,
                micOn: false,
                cameraOn: false,
                screenOn: false,
              });
            } else {
              quitCall(CALL_END_REASON.LEAVE, true);
            }
            if (callProgress === CALL_PROGRESS.IN_CALL_SCREEN) {
              message.success(t("ROLE_UPDATED", { role: capitalizeFirstLetter(actOn?.member?.role || "") }), TOAST_TIME);
            }
          } else {
            updateDeepDiveState({ audioChannels });
            getMembers();
          }
          break;
        }

        // End call
        case SIGNAL_TYPE.END_CALL: {
          quitCall(CALL_END_REASON.END_CALL, false, msg);
          break;
        }

        // Recording status changed
        case SIGNAL_TYPE.RECORDING_STATUS_CHANGED: {
          if (callProgress === CALL_PROGRESS.IN_CALL_SCREEN) {
            message.success(`${msg}`, TOAST_TIME);
          }
          updateDeepDiveState({ recordingInfo: recordingInfo });
          break;
        }

        // Interactions between participants changed
        case SIGNAL_TYPE.INTERACTIONS_BETWEEN_PARTICIPANTS_CHANGED: {
          if (interactionBetweenParticipants !== undefined) {
            updateDeepDiveState({ sessionInfo: { ...(sessionInfo as SessionInfo), interactionBetweenParticipants } });

            if (
              callProgress !== CALL_PROGRESS.ERROR_SCREEN &&
              callProgress !== CALL_PROGRESS.THANK_YOU_SCREEN &&
              (!actOn?.uid ||
                (actOn?.uid === cameraAudioUid && interactionBetweenParticipants === INTERACTIONS_BETWEEN_PARTICIPANTS_TYPE.OFF))
            ) {
              const { msg, descriptionMsg } = limitInteractionDesc(interactionBetweenParticipants);
              message.info(
                <div className='d-flex align-items-center gap-1'>
                  <Text className='color-2E62E9' strong>
                    {msg}
                  </Text>
                  <Text className='color-2E62E9'>{descriptionMsg}</Text>
                </div>,
                TOAST_TIME
              );
            }
          }
          break;
        }

        default:
          break;
      }
    }
  }, []);

  // Setting up RTM for signaling
  useEffect(() => {
    // Subscribe to RTM channel
    const subscribe = async () => {
      try {
        const subscribeOptions = {
          withMessage: true,
          withPresence: true,
          withMetadata: true,
          withLock: true,
        };
        await rtmRef.current?.subscribe(channel, subscribeOptions);
      } catch (error) {
        console.log("Error in subscribing to channel: ", error);
      }
    };

    // Initialize RTM
    const initializeRTM = async () => {
      if (appId && cameraAudioUid && rtmToken) {
        try {
          const rtmInstance = new RTM(appId, String(cameraAudioUid));
          rtmRef.current = rtmInstance;
          console.log("RTM initialized successfully");
          try {
            await rtmInstance.login({ token: rtmToken });
            console.log("Logged in RTM successfully");
            subscribe();
            rtmInstance.addEventListener("message", listener);
          } catch (status) {
            console.log("Error in logging in RTM: ", status);
          }
        } catch (status) {
          console.log("Error in initializing RTM: ", status);
        }
      }
    };

    initializeRTM();

    return () => {
      // Clean up
      if (appId && cameraAudioUid && rtmRef.current) {
        rtmRef.current.removeEventListener("message", listener);
        rtmRef.current.logout();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId, cameraAudioUid, rtmToken, channel]);

  // Send signal to peers
  const sendSignal = useCallback(
    async (
      event: SIGNAL_TYPE,
      uid: number,
      joineeId: number,
      member: Member | undefined,
      actOn?: WaitingParticipantInterface | undefined,
      chat?: ChatMessage | undefined,
      msg?: string,
      isReloadRequired?: boolean,
      recordingInfo?: RecordingInfo | undefined,
      audioChannels?: AudioChannelsInterface | undefined,
      interactionBetweenParticipants?: INTERACTIONS_BETWEEN_PARTICIPANTS_TYPE | undefined
    ) => {
      const message: SignalMessage = {
        event,
        from: {
          uid,
          joineeId,
          member,
        },
        actOn,
        chat,
        msg,
        isReloadRequired,
        recordingInfo,
        audioChannels,
        interactionBetweenParticipants,
        timeStamp: Math.floor(Date.now() / 1000),
      };

      const payload = { type: "text", message };
      const publishMessage = JSON.stringify(payload);
      try {
        const result = await rtmRef.current?.publish(channel, publishMessage);
        console.log("Signal sent successfully: ", result, payload);
      } catch (status) {
        console.log("Error in sending signal: ", status);
      }
    },
    [channel]
  );

  const memoizedValue = useMemo<RTMContextType>(
    () => ({
      sendSignal,
    }),
    [sendSignal]
  );

  return <RTMContext.Provider value={memoizedValue}>{children}</RTMContext.Provider>;
};

RTMProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RTMProvider;
