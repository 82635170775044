import { useMemo } from "react";
import { RemoteStreams } from "../../constants";
import DynamicGrid from "../layouts/DynamicGrid";
import NoScreenShared from "./NoScreenShared";
import RemoteUserStream from "./RemoteUserStream";

const IDT = ({ remoteStreams }: { remoteStreams: RemoteStreams }) => {
  const noScreensShared = useMemo(() => {
    return !remoteStreams || (remoteStreams && !remoteStreams.moderatorScreens.length && !remoteStreams.participantScreens.length);
  }, [remoteStreams]);

  return (
    <>
      {noScreensShared && <NoScreenShared />}

      {!noScreensShared && remoteStreams.moderatorScreens.length > 0 && (
        <DynamicGrid>
          {remoteStreams.moderatorScreens.map((user) => {
            return <RemoteUserStream user={user} className='middle-user' key={user.uid} />;
          })}
        </DynamicGrid>
      )}

      {!noScreensShared && remoteStreams.participantScreens.length > 0 && (
        <DynamicGrid>
          {remoteStreams.participantScreens.map((user) => {
            return <RemoteUserStream user={user} className='middle-user' key={user.uid} />;
          })}
        </DynamicGrid>
      )}
    </>
  );
};

export default IDT;
