import { useEffect, useState } from "react";
import { useDeepDiveContext } from "../../DeepDiveProvider";
import { ConstraintsInterface, SessionInfo } from "../../deepdiveSlice";

interface VideoCallTimerProps {
  onWarning: (timeLeft: number) => void; // Function to show warning
  onEndCall: () => void; // Function to end the call
}

const WARNING_TIME = 5; // Time to warn user before end call (in minutes)

const VideoCallTimer: React.FC<VideoCallTimerProps> = ({ onWarning, onEndCall }) => {
  const { sessionInfo, constraints } = useDeepDiveContext();
  const { start_time } = sessionInfo as SessionInfo;
  const { maxAllowedDurationMins } = constraints as ConstraintsInterface;

  const [warningTriggered, setWarningTriggered] = useState(false);

  useEffect(() => {
    const currentTime = Math.floor(Date.now() / 1000); // Get current time in seconds
    const callDuration = currentTime - start_time; // Calculate elapsed time in seconds
    const remainingMinutes = maxAllowedDurationMins - callDuration / 60; // Calculate remaining time in minutes

    // Check if we need to show a warning
    if (remainingMinutes <= WARNING_TIME && remainingMinutes > 0 && !warningTriggered) {
      onWarning(remainingMinutes); // Trigger the warning `WARNING_TIME` minutes before the call ends
      setWarningTriggered(true); // Mark warning as triggered
    }

    // Check if we need to end the call
    if (remainingMinutes <= 0) {
      onEndCall(); // Trigger call end
    }

    // Set up interval to update time remaining every second
    const interval = setInterval(() => {
      const updatedTime = Math.floor(Date.now() / 1000);
      const newCallDuration = updatedTime - start_time;
      const newRemainingMinutes = maxAllowedDurationMins - newCallDuration / 60;

      if (newRemainingMinutes <= WARNING_TIME && newRemainingMinutes > 0 && !warningTriggered) {
        onWarning(newRemainingMinutes);
        setWarningTriggered(true); // Mark warning as triggered
      }

      if (newRemainingMinutes <= 0) {
        onEndCall();
        clearInterval(interval); // Stop the timer once the call ends
      }
    }, 1000);

    return () => clearInterval(interval); // Cleanup the interval on unmount
  }, [onWarning, onEndCall, start_time, maxAllowedDurationMins, warningTriggered]);

  return <></>;
};

export default VideoCallTimer;
