import { Typography } from "antd";
import PropTypes from "prop-types";
import CustomIcon from "../CustomIcon";

const { Paragraph } = Typography;

const CopyText = ({ link = "", className = "", iconClass = "", onCopy = () => {}, dataTestId = "" }) => {
  return (
    <div className={`p-1 br-5px border-E3E8EF bg-white ${className}`} data-testid={dataTestId}>
      <Paragraph
        ellipsis
        className='m-0 d-flex align-items-center space-between'
        copyable={{ icon: <CustomIcon name='copy-06' className={iconClass} />, onCopy, tooltips: false }}
      >
        {link}
      </Paragraph>
    </div>
  );
};

CopyText.propTypes = {
  link: PropTypes.string,
  className: PropTypes.string,
  iconClass: PropTypes.string,
  onCopy: PropTypes.func,
  dataTestId: PropTypes.string,
};

export default CopyText;
