import { ICameraVideoTrack, IMicrophoneAudioTrack, LocalUser } from "agora-rtc-react";
import SimpleBar from "simplebar-react";
import UserTrackInfo from "../UserTrackInfo";
import { RemoteStreams, USER_TYPE } from "../../constants";
import DynamicGrid from "../layouts/DynamicGrid";
import { useDeepDiveContext } from "../../DeepDiveProvider";
import { Joinee } from "../../deepdiveSlice";
import RemoteUserStream from "./RemoteUserStream";
import { useMemo } from "react";

const FocusGroup = ({
  localMicrophoneTrack,
  localCameraTrack,
  remoteStreams,
}: {
  localMicrophoneTrack: IMicrophoneAudioTrack | null;
  localCameraTrack: ICameraVideoTrack | null;
  remoteStreams: RemoteStreams;
}) => {
  const { cameraOn, micOn, joinee } = useDeepDiveContext();
  const {
    joinee: { role },
  } = joinee as Joinee;

  const noParticipantsScreensShared = useMemo(() => {
    return !remoteStreams || !remoteStreams.participantScreens.length;
  }, [remoteStreams]);

  const noModeratorScreensShared = useMemo(() => {
    return !remoteStreams || !remoteStreams.moderatorScreens.length;
  }, [remoteStreams]);

  const ParticipantSelfCam = ({ className }: { className: string }) => {
    return (
      <div className={`user ${className}`}>
        <LocalUser
          className='localUser'
          audioTrack={localMicrophoneTrack}
          cameraOn={cameraOn}
          micOn={micOn}
          videoTrack={localCameraTrack}
          playAudio={false}
        >
          <UserTrackInfo isLocal={true} />
        </LocalUser>
      </div>
    );
  };

  return (
    <>
      {noParticipantsScreensShared && noModeratorScreensShared && (
        <div className='h-100 w-100'>
          <DynamicGrid>
            {remoteStreams?.participantCamerasMics.map((user) => {
              return <RemoteUserStream isSideBar={true} user={user} className='middle-user' key={user.uid} />;
            })}
            {role === USER_TYPE.TESTER && <ParticipantSelfCam className='middle-user' />}
          </DynamicGrid>
        </div>
      )}

      {(!noParticipantsScreensShared || !noModeratorScreensShared) && (
        <>
          <SimpleBar className='w-100 h-130px'>
            <div className='d-flex gap-2 flex-row pb-2'>
              {remoteStreams.participantCamerasMics.map((user) => {
                return <RemoteUserStream isSideBar={true} user={user} className='flex-shrink-0' key={user.uid} />;
              })}
              {role === USER_TYPE.TESTER && <ParticipantSelfCam className='flex-shrink-0' />}
            </div>
          </SimpleBar>
          <div className='mt-1' style={{ height: "calc(100% - 130px)" }}>
            {remoteStreams.participantScreens.length > 0 && (
              <DynamicGrid>
                {remoteStreams.participantScreens.map((user) => {
                  return <RemoteUserStream user={user} className='middle-user' key={user.uid} />;
                })}
              </DynamicGrid>
            )}
            {remoteStreams.moderatorScreens.length > 0 && (
              <DynamicGrid>
                {remoteStreams.moderatorScreens.map((user) => {
                  return <RemoteUserStream user={user} className='middle-user' key={user.uid} />;
                })}
              </DynamicGrid>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default FocusGroup;
