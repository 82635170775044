import { Button, Input, Tooltip, Typography } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDeepDiveContext } from "../../DeepDiveProvider";
import { Feature, Joinee, NotesInterface, SessionInfo } from "../../deepdiveSlice";
import CustomIcon from "../CustomIcon";
import { SendOutlined } from "@ant-design/icons";
import SimpleBar from "simplebar-react";
import { api } from "../../api";
import { createHyperlinks, formatTimestampToTime } from "../../constants";

const { Text } = Typography;

const NotesInput = ({ sendMessage, t }: { sendMessage: (msg: string) => void; t: any }) => {
  const [inputMsg, setInputMsg] = useState("");
  const textAreaRef = useRef<any>(null);

  const handleSendMessage = () => {
    if (inputMsg?.trim().length) {
      sendMessage(inputMsg);
      setInputMsg("");
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    const pasteText = e.clipboardData.getData("text");
    const currentText = inputMsg;
    const newText = currentText + pasteText;

    if (newText.length > 1000) {
      e.preventDefault();
      const trimmedText = newText.substring(0, 1000);
      setInputMsg(trimmedText);
    }

    // Trigger manual resizing after paste
    setTimeout(() => {
      if (textAreaRef.current?.resizableTextArea?.textArea) {
        // Focus and blur the actual textarea to force a resize
        const actualTextArea = textAreaRef.current.resizableTextArea.textArea;
        actualTextArea.blur();
        actualTextArea.focus();
      }
    }, 0);
  };

  return (
    <div className='chat-input-container d-flex align-items-end gap-1 bg-FFF9EB'>
      <Input.TextArea
        ref={textAreaRef}
        className='chat-input bg-FFF9EB resize-none font-size-14px'
        placeholder={t("ENTER_YOUR_NOTES_PLACEHOLDER")}
        value={inputMsg}
        onChange={(e) => setInputMsg(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
          }
        }}
        size='large'
        rows={7}
        style={{ flex: 1 }}
        maxLength={1000}
        onPaste={handlePaste}
      />
      <Tooltip title={t("SEND_MESSAGE")} placement='left' rootClassName='zi-tooltip'>
        <Button className='send-button' type='text' disabled={inputMsg.trim().length === 0} onClick={handleSendMessage}>
          <SendOutlined />
        </Button>
      </Tooltip>
    </div>
  );
};

const Notes = () => {
  const { setShowFeature, notes, sessionInfo, joinee, updateDeepDiveState, t } = useDeepDiveContext();
  const { id: sessionId } = sessionInfo as SessionInfo;
  const {
    joinee: { role, name, id },
  } = joinee as Joinee;

  const [isCollapsed, setIsCollapsed] = useState(true);

  const sendMessage = useCallback(
    (msg: string) => {
      const note: NotesInterface = {
        timestamp: Math.floor(Date.now() / 1000),
        connectionID: id,
        userRole: role,
        userId: role,
        userName: name,
        comment: msg?.trim(),
        sessionStartTime: sessionInfo?.start_time || 0,
      };
      api
        .saveNote(sessionId, note)
        .then((data) => {
          const newNote = data?.data?.notes as NotesInterface;
          if (newNote) {
            updateDeepDiveState({ notes: [...(notes ?? []), newNote] });
          }
        })
        .catch(() => {});
    },
    [id, name, notes, role, sessionId, sessionInfo?.start_time, updateDeepDiveState]
  );

  const MsgView = ({ note }: { note: NotesInterface }) => {
    return (
      <div className='d-flex flex-center flex-column gap-1 bg-FFF3D6 p-8-12 border-8px'>
        <div className='d-flex flex-column gap-1'>
          <Text type='secondary' className='font-size-12px'>
            {formatTimestampToTime(note.timestamp)}
          </Text>
          <div className='msg-view word-break' dangerouslySetInnerHTML={{ __html: createHyperlinks(note.comment) }} />
        </div>
      </div>
    );
  };

  const NotesWindow = () => {
    const simpleBarRef = useRef<HTMLElement | null>(null);

    useEffect(() => {
      if (simpleBarRef.current) {
        const scrollElement = simpleBarRef.current;
        scrollElement.scrollTop = scrollElement.scrollHeight;
      }
    }, []);

    return (
      <div className='h-100 bg-FFF9EB'>
        {(notes?.length ?? 0) > 0 && (
          <SimpleBar className='w-100 h-100' scrollableNodeProps={{ ref: simpleBarRef }}>
            <div className='d-flex flex-center flex-column gap-2 p-2'>
              {notes?.map((note, index) => (
                <MsgView note={note} key={index} />
              ))}
            </div>
          </SimpleBar>
        )}
      </div>
    );
  };

  return (
    <div
      style={{ width: 250, height: isCollapsed ? "auto" : 500 }}
      className='bg-FFF9EB bs position-absolute top-8 zi-10k overflow-hidden'
    >
      <div className='d-flex gap-1 p-1 bg-FFE7AD space-between'>
        <Button
          className='m-0'
          size='small'
          type='text'
          onClick={() => {
            setShowFeature((prev) => (prev !== Feature.NOTES ? Feature.NOTES : undefined));
          }}
        >
          <CustomIcon name='x-close' />
        </Button>
        <Text className='font-weight-600'>{t("TAKE_NOTES")}</Text>
        <Tooltip title={isCollapsed ? t("SHOW_NOTES_HISTORY") : t("HIDE_NOTES_HISTORY")} rootClassName='zi-tooltip'>
          <Button
            className='m-0'
            size='small'
            type='text'
            onClick={() => {
              setIsCollapsed((prev) => !prev);
            }}
          >
            {(notes?.length ?? 0) > 0 && (isCollapsed ? <CustomIcon name='columns-expand' /> : <CustomIcon name='columns-collapse' />)}
          </Button>
        </Tooltip>
      </div>

      {!isCollapsed && (
        <div style={{ height: `calc(100% - 44px - 180px)` }}>
          <NotesWindow />
        </div>
      )}
      <NotesInput sendMessage={sendMessage} t={t} />
    </div>
  );
};

export default Notes;
