import { useRoutes } from "react-router-dom";
import Error from "./Error";
import Entry from "./components/Entry";

export default function Router() {
  return useRoutes([
    { path: "/:joiningKey", element: <Entry /> },
    { path: "*", element: <Error /> },
  ]);
}
