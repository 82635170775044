import { ConfigProvider } from "antd";
import { ReactNode } from "react";

interface ThemeProviderProps {
  children: ReactNode;
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  return (
    <ConfigProvider
      theme={{
        hashed: false,
        token: {
          controlHeight: 36,
          fontFamily: "Inter",
          colorInfo: "#2E62E9",
          colorPrimary: "#2E62E9",
          colorSuccess: "#4EBF61",
          colorWarning: "#FA942E",
          colorTextHeading: "#121926",
          colorTextBase: "#364152",
        },
        components: {
          Typography: { colorText: "#364152", colorTextDescription: "#697586", fontWeightStrong: 600, fontSize: 14 },
          Button: {
            colorPrimary: "#2E62E9",
            textHoverBg: "#F8FAFC",
            colorPrimaryHover: "#2e62e9b3",
            colorText: "#2E62E9",
            colorLink: "#2E62E9",
            colorLinkHover: "#2E62E9",
          },
          Spin: { dotSizeLG: 36, colorPrimary: "#2E62E9" },
          Tabs: {
            colorBorder: "#2E62E9",
            colorPrimaryBorder: "#2E62E9",
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default ThemeProvider;
