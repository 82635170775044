import axios, { Method, AxiosResponse, AxiosError } from "axios";

// BASE_URL of Server
axios.defaults.withCredentials = true;
axios.defaults.headers["Pragma"] = "no-cache";
axios.defaults.headers["Cache-Control"] = "no-cache";
axios.defaults.headers["Content-Type"] = "application/json";
axios.defaults.baseURL = process.env.REACT_APP_SESSION_MANAGER_URL as string;

interface AxiosCallParams {
  url: string;
  method?: Method;
  data?: any;
}

// Axios call
export const axiosCall = ({ url, method = "post", data = {} }: AxiosCallParams): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios({
      url,
      method,
      data,
    })
      .then((response: AxiosResponse) => resolve(response.data))
      .catch((error: AxiosError) => reject(error));
  });
};

export default axios;
