import { useEffect, useMemo, useRef } from "react";
import { useJoin, useLocalScreenTrack, usePublish, useTrackEvent } from "agora-rtc-react";
import { useDeepDiveContext } from "../DeepDiveProvider";
import { CALL_PROGRESS, SIGNAL_TYPE, TOAST_TIME } from "../constants";
import { Joinee, Uids } from "../deepdiveSlice";
import { App } from "antd";
import { useRTMContext } from "../RTMProvider";

const ScreenSharing = () => {
  const { appId, channel, tokens, callProgress, screenOn, joinee, updateDeepDiveState, members, t, calling } = useDeepDiveContext();
  const { sendSignal } = useRTMContext();
  const {
    joinee: { id },
    uids = {},
  } = joinee as Joinee;
  const { screenUid } = uids as Uids;
  const { screen: screenToken = "" } = tokens || {};

  const member = useMemo(() => members && members[screenUid], [members, screenUid]);
  const memberRef = useRef(member);

  const { message } = App.useApp();

  useEffect(() => {
    memberRef.current = member;
  }, [member]);

  useJoin(
    { appid: appId, channel: channel, token: screenToken ? screenToken : null, uid: screenUid },
    callProgress === CALL_PROGRESS.IN_CALL_SCREEN && screenOn && calling
  );

  // local user
  const { screenTrack, error } = useLocalScreenTrack(screenOn, {}, "disable");

  // screen share started
  useEffect(() => {
    if (screenOn && screenTrack) {
      sendSignal(SIGNAL_TYPE.SCREEN_SHARE_STARTED, screenUid, id, memberRef.current);
    }
  }, [id, screenOn, screenTrack, screenUid, sendSignal]);

  // error while sharing screen
  useEffect(() => {
    if (screenOn && error) {
      message.error(t("FAILED_SCREEN"), TOAST_TIME);
      updateDeepDiveState({ screenOn: false });
    }
  }, [error, message, screenOn, t, updateDeepDiveState]);

  usePublish([screenTrack], screenOn);

  // screen share closed
  useTrackEvent(screenTrack, "track-ended", () => {
    if (screenOn) updateDeepDiveState({ screenOn: false });
  });

  return <></>;
};

export default ScreenSharing;
