import { Tag, Typography } from "antd";
import { getBackground } from "../../constants";

const { Text } = Typography;

const UserInfo = ({ userName, isSmall = false, isSelf = false }: { userName: string; isSmall?: boolean; isSelf?: boolean }) => {
  const displayUserName = isSelf ? "You" : userName;
  return (
    <div className='d-flex align-items-center'>
      <Tag
        className={`border-radius-circle d-flex align-items-center flex-center ${!isSmall ? "w-34px h-34px" : "w-24px h-24px"}`}
        style={{ backgroundColor: getBackground(userName || "") }}
      >
        <Text className={`font-weight-500 color-white text-capitalize ${!isSmall ? "font-size-17px" : "font-size-12px"}`}>
          {userName?.toString()?.charAt(0)}
        </Text>
      </Tag>
      <Text className={`font-weight-500 ${!isSmall ? "font-size-14px" : "font-size-12px"}`}>{displayUserName}</Text>
    </div>
  );
};

export default UserInfo;
