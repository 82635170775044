import { Button, Typography } from "antd";
import CustomIcon from "../CustomIcon";
import { UserAddOutlined } from "@ant-design/icons";
import { useDeepDiveContext } from "../../DeepDiveProvider";
import { Feature } from "../../deepdiveSlice";

const { Text } = Typography;

const NoParticipants = () => {
  const { setShowFeature, t } = useDeepDiveContext();

  return (
    <div className='d-flex flex-column align-items-center flex-center gap-2 w-220px text-center m-auto h-100'>
      <CustomIcon name='noParticipants' />
      <Text>{t("INVITE_PARTICIPANT")}</Text>
      <Button
        icon={<UserAddOutlined />}
        type='primary'
        onClick={() => {
          setShowFeature((prev) => (prev !== Feature.INVITE ? Feature.INVITE : undefined));
        }}
      >
        {t("INVITE")}
      </Button>
    </div>
  );
};

export default NoParticipants;
