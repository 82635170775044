import { Spin, Typography } from "antd";
import CustomIcon from "../CustomIcon";
import { useEffect, useRef } from "react";
import { useDeepDiveContext } from "../../DeepDiveProvider";
import { useParams } from "react-router-dom";

const { Title, Text } = Typography;

const WaitingScreen = () => {
  const { joiningKey } = useParams();
  const { verifySession, autoJoin, t } = useDeepDiveContext();
  const hasFetched = useRef(false);

  useEffect(() => {
    if (joiningKey && !autoJoin) {
      if (hasFetched.current) return;
      hasFetched.current = true;

      verifySession(joiningKey || "");
    }
  }, [autoJoin, joiningKey, verifySession]);

  return (
    <>
      {!autoJoin && (
        <>
          <CustomIcon name='deepdive' />
          <div className='d-flex flex-column flex-center align-items-center mt-4'>
            <CustomIcon name='higirl' />
            <Title className='font-size-30px'>{t("WELCOME")}</Title>
            <Text className='font-size-17px color-426BCD'>{t("SYSTEM_PREPARING")}</Text>
            <Text className='font-size-17px color-426BCD'>{t("WAIT_FOR_FETCHING_SESSION_DETAILS")}</Text>
            <Spin className='mt-4' size='large' />
          </div>
        </>
      )}
    </>
  );
};

export default WaitingScreen;
