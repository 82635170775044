import React from "react";

interface DynamicGridProps {
  children: React.ReactNode[];
}

const DynamicGrid: React.FC<DynamicGridProps> = ({ children }) => {
  const validChildren = React.Children.toArray(children).filter(Boolean);

  return (
    <div className='dynamic-grid-wrapper w-100 h-100'>
      <div className={`dynamic-grid grid-${validChildren.length} w-100 h-100`}>{children}</div>
    </div>
  );
};

export default DynamicGrid;
